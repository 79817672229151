import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  Card, CardContent, CardHeader, Divider,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Chart } from '../__common__/Chart';

const buildChartOptions = ({ theme, categories }) => {
  const chartOptions = {
    chart: {
      background: 'transparent',
      toolbar: {
        show: false,
      },
    },
    colors: ['#72E2A6', '#6891F5', '#9F6AF5', '#D185D5', '#FF874A', '#FFDC37'],
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 0,
      type: 'solid',
    },
    grid: {
      borderColor: theme.palette.divider,
    },
    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
        show: true,
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true,
      },
      categories,
    },
  };

  return chartOptions;
};

export default function FinanceSalesRevenue({ data, categories }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const chartOptions = buildChartOptions({ theme, categories });

  // console.log('FinanceSalesRevenue render', {
  //   data,
  //   chartOptions,
  //   categories,
  // }); // TODO: Remove later [L]

  return (
    <Card>
      <CardHeader title={t('Sales channel breakdown')} />
      <Divider />
      <CardContent>
        <Chart height={467} options={chartOptions} series={data} type="area" />
      </CardContent>
    </Card>
  );
}

FinanceSalesRevenue.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  categories: PropTypes.arrayOf(PropTypes.string),
};

FinanceSalesRevenue.defaultProps = {
  data: [],
  categories: [],
};
