import { t } from 'i18next';
import PropTypes from 'prop-types';

import { Card, CardHeader, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Chart } from '../__common__/Chart';

const buildChartOptions = ({ theme, data, toolTipFormatter }) => {
  const options = {
    legend: {
      show: false,
    },
    chart: {
      background: 'transparent',
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: '12px',
      },
      x: {
        show: true,
        formatter(_idx, { dataPointIndex }) {
          const dataPoint = data.metaData[dataPointIndex];
          return `${t('Contract')}:`.concat(dataPoint?.contractId);
        },
      },
      y: {
        formatter: toolTipFormatter,
      },
      marker: {
        show: true,
      },
      formatter(text, op) {
        const dataPoint = data.metaData[op.dataPointIndex];
        return [`${t('Contract')}:`.concat(text, dataPoint?.salesChannel), op.value];
      },
      offsetY: -4,
    },
    colors: data.colors,
    plotOptions: {
      treemap: {
        enableShades: false,
        distributed: true,
        useFillColorAsStroke: false,
      },
    },
    theme: {
      mode: theme.palette.mode,
    },
  };

  return options;
};

export default function ContractProfitabilityTreemap({ data, title }) {
  const theme = useTheme();

  const toolTipFormatter = (value, { dataPointIndex }) => {
    const dataPoint = data.metaData[dataPointIndex];
    const salesChannel = dataPoint?.salesChannel ?? 'No sales channel';
    const product = dataPoint?.product ?? 'No Product defined';
    const margin = dataPoint?.margin ?? '';
    const marginAbs = dataPoint?.marginAbs ?? '';
    const consumption = dataPoint?.consumption ?? '';
    return `${t('Sales Channel')}: ${salesChannel}<br>${t('Product')}: ${product}<br>${t('Margin')}: ${margin}<br>${t(
      'EBIT',
    )}: ${marginAbs}<br>${t('Consumption')}: ${consumption}`;
  };

  const options = buildChartOptions({ data, theme, toolTipFormatter });

  return (
    <Card>
      <CardHeader title={title} />
      <Divider />

      <Card
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2,
        }}
      >
        <Chart height={400} width={400} options={options} series={data.series} type="treemap" />
      </Card>
    </Card>
  );
}

ContractProfitabilityTreemap.propTypes = {
  data: PropTypes.shape({
    metaData: PropTypes.arrayOf(PropTypes.shape({})),
    series: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  title: PropTypes.string,
};

ContractProfitabilityTreemap.defaultProps = {
  data: {},
  title: 'Quality of customers',
};
