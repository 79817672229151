import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Chart } from '../__common__/Chart';

function buildChartOptions({ data, theme }) {
  const chartOptions = {
    chart: {
      background: 'transparent',
      toolbar: {
        show: false,
      },
    },
    colors: data.map((item) => item.color),
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    labels: data.map((item) => item.label),
    legend: {
      show: false,
    },
    stroke: {
      width: 0,
    },
    theme: {
      mode: theme.palette.mode,
    },
  };

  return chartOptions;
}

export default function FinanceProductBreakdown({ data }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const chartOptions = buildChartOptions({ data, theme });
  const chartSeries = data.map((item) => item.count);

  const renderTable = () => (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('Top Products Last 30 days')}</TableCell>
          <TableCell align="right">{t('Contracts')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item) => (
          <TableRow key={item.id}>
            <TableCell>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Box
                  sx={{
                    border: 3,
                    borderColor: item.color,
                    backgroundColor: item.color,
                    borderRadius: '50%',
                    height: 16,
                    mr: 1,
                    width: 16,
                  }}
                />
                <Typography variant="subtitle2">{item.label}</Typography>
              </Box>
            </TableCell>
            <TableCell align="right">
              <Typography color="textSecondary" variant="body2">
                {numeral(item.count).format('0,0')}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <Card>
      <CardHeader title={t('Product Breakdown')} />
      <Divider />

      <CardContent>
        <Chart height={240} options={chartOptions} series={chartSeries} type="pie" />
      </CardContent>

      {renderTable()}
    </Card>
  );
}

FinanceProductBreakdown.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

FinanceProductBreakdown.defaultProps = {
  data: [],
};
