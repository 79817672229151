import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Avatar, Box, Button, Card, CardActions, Divider, Grid, Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import numeral from 'numeral';

import { ArrowRight as ArrowRightIcon } from '../../../icons/arrow-right';
import { ChevronUp as ChevronUpIcon } from '../../../icons/chevron-up';
import { ChevronDown as ChevronDownIcon } from '../../../icons/chevron-down';
import Utils from '../../../utils/helpers';
import { SeverityPill } from '../../SeverityPill';
import LineChart from './LineChart';
import BarChart from './BarChart';

export default function AnalyticsGeneralOverview({ data }) {
  const { t } = useTranslation();

  const suppliedContractsRelationColorName = Utils.getColorByRelationValue(data.suppliedContractsRelation);
  const suppliedAndPendingContractsRelationColorName = Utils.getColorByRelationValue(
    data.suppliedAndPendingContractsRelation,
  );

  const renderArrow = (value) => {
    if (value === 'error') {
      return <ChevronDownIcon fontSize="small" />;
    }
    return <ChevronUpIcon fontSize="small" />;
  };

  return (
    <Grid container spacing={4}>
      <Grid item md={4} sm={6} xs={12}>
        <Card>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              px: 3,
              py: 2,
            }}
          >
            <div>
              <Typography color="textSecondary" variant="h3">
                {t('New contracts (24h)')}
              </Typography>
              <Typography sx={{ mt: 1 }} variant="h5">
                {numeral(data.newContractsCount).format('0,0')}
              </Typography>
            </div>
            <LineChart data={data.newContractsCountValuesHistory} />
          </Box>

          <Divider />

          <CardActions>
            <Button endIcon={<ArrowRightIcon fontSize="small" />}>{t('See all')}</Button>
          </CardActions>
        </Card>
      </Grid>

      <Grid item md={4} sm={6} xs={12}>
        <Card>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              px: 3,
              py: 2,
            }}
          >
            <div>
              <Typography color="textSecondary" variant="h3">
                {t('Supplied contracts')}
              </Typography>
              <Typography sx={{ mt: 1 }} variant="h5">
                {numeral(data.suppliedContractsCount).format('0,0')}
              </Typography>
            </div>
            <LineChart data={data.suppliedContractsCountValuesHistory} />
          </Box>
          <Divider />
          <CardActions
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Avatar
              sx={{
                backgroundColor: (theme) => alpha(theme.palette[suppliedContractsRelationColorName].main, 0.08),
                color: `${suppliedContractsRelationColorName}.main`,
                height: 36,
                width: 36,
              }}
            >
              {renderArrow(suppliedContractsRelationColorName)}
            </Avatar>
            <Typography color="textSecondary" sx={{ ml: 1 }} variant="caption">
              <SeverityPill color={suppliedContractsRelationColorName}>
                {numeral(data.suppliedContractsRelation).format('0.0')}
                %
              </SeverityPill>
              {' '}
              {t('more then last month')}
            </Typography>
          </CardActions>
        </Card>
      </Grid>

      <Grid item md={4} sm={6} xs={12}>
        <Card>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              px: 3,
              py: 2,
            }}
          >
            <div>
              <Typography color="textSecondary" variant="h3">
                {t('Supplied and pending contracts')}
              </Typography>
              <Typography sx={{ mt: 1 }} variant="h5">
                {numeral(data.suppliedAndPendingContractsCount).format('0,0')}
              </Typography>
            </div>
            <BarChart data={data.suppliedAndPendingContractsCountValuesHistory} />
          </Box>
          <Divider />
          <CardActions
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Avatar
              sx={{
                backgroundColor: (theme) => alpha(theme.palette[suppliedAndPendingContractsRelationColorName].main, 0.08),
                color: `${suppliedAndPendingContractsRelationColorName}.main`,
                height: 36,
                width: 36,
              }}
            >
              {renderArrow(suppliedAndPendingContractsRelationColorName)}
            </Avatar>
            <Typography color="textSecondary" sx={{ ml: 1 }} variant="caption">
              <SeverityPill color={suppliedAndPendingContractsRelationColorName}>
                {numeral(data.suppliedAndPendingContractsRelation).format('0.0')}
                %
              </SeverityPill>
              {' '}
              {t('more then last month')}
            </Typography>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}

AnalyticsGeneralOverview.propTypes = {
  data: PropTypes.shape({
    suppliedContractsCount: PropTypes.number,
    newContractsCount: PropTypes.number,
    suppliedAndPendingContractsCount: PropTypes.number,
    suppliedContractsRelation: PropTypes.number,
    suppliedAndPendingContractsRelation: PropTypes.number,
    newContractsCountValuesHistory: PropTypes.arrayOf(PropTypes.number),
    suppliedContractsCountValuesHistory: PropTypes.arrayOf(PropTypes.number),
    suppliedAndPendingContractsCountValuesHistory: PropTypes.arrayOf(PropTypes.number),
  }),
};

AnalyticsGeneralOverview.defaultProps = {
  data: {},
};
