import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, Container, Grid, Typography,
} from '@mui/material';
import { AuthGuard } from '../components/Authentication/AuthGuard';
import PageLayout from '../components/Layout/PageLayout';
import FinanceOverview from '../components/Home/FinanceOverview';
import FinanceSalesRevenue from '../components/Home/FinanceSalesRevenue';
import FinanceProductBreakdown from '../components/Home/FinanceProductBreakdown';
import ContractProfitabilityTreemap from '../components/Home/ContractProfitabilityTreemap';
import AnalyticsGeneralOverview from '../components/Home/AnalyticsGeneralOverview';
import useOperationWrapper from '../hooks/useOperationWrapper';
import SplashScreen from '../components/SplashScreen';
import * as PricesAPI from '../utils/api/prices';

const DEFAULTS = {
  PRODUCTS_DATA: [],
  OVERVIEW_DATA: {},
  CONTRACT_MARGIN_DATA: {},
  SALES_CHANNELS_DATA: {},
};

function HomePage() {
  const { t } = useTranslation();
  const { serverError, isLoading, operationWrapper } = useOperationWrapper();

  const [productsData, setProductsData] = useState(DEFAULTS.PRODUCTS_DATA);
  const [overviewData, setOverviewData] = useState(DEFAULTS.OVERVIEW_DATA);
  const [salesChannelsData, setSalesChannelsData] = useState(DEFAULTS.SALES_CHANNELS_DATA);
  const [contractMarginDataElectricity, setContractMarginDataElectricity] = useState(DEFAULTS.CONTRACT_MARGIN_DATA);
  const [contractMarginDataGas, setContractMarginDataGas] = useState(DEFAULTS.CONTRACT_MARGIN_DATA);

  useEffect(() => {
    operationWrapper(async () => {
      const dashboardProducts = await PricesAPI.getDashboardProducts();
      setProductsData(dashboardProducts);

      const dashboardOverview = await PricesAPI.getDashboardOverview();
      setOverviewData(dashboardOverview);

      const dashboardSalesChannels = await PricesAPI.getDashboardSalesChannels();
      setSalesChannelsData(dashboardSalesChannels);

      // TODO: Move the energyTypeId to constants and reuse [L]
      const dashboardContractMarginElectricity = await PricesAPI.getDashboardContractMargin({ energyTypeId: 1 });
      setContractMarginDataElectricity(dashboardContractMarginElectricity);

      const dashboardContractMarginGas = await PricesAPI.getDashboardContractMargin({ energyTypeId: 2 });
      setContractMarginDataGas(dashboardContractMarginGas);
    });
  }, []);

  console.log('HomePage render', {
    isLoading,
    serverError,
    productsData,
    overviewData,
    contractMarginDataElectricity,
    contractMarginDataGas,
    salesChannelsData,
  }); // TODO: Remove later [L]

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="xl">
        <Box sx={{ mb: 4 }}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography variant="h2">{t('Home')}</Typography>
            </Grid>
          </Grid>
        </Box>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <AnalyticsGeneralOverview data={overviewData} />
          </Grid>

          <Grid item xs={12}>
            <FinanceOverview data={overviewData} />
          </Grid>

          <Grid item md={8} xs={12}>
            {salesChannelsData?.series?.length ? (
              <FinanceSalesRevenue data={salesChannelsData.series} categories={salesChannelsData.categories} />
            ) : null}
          </Grid>

          <Grid item md={4} xs={12}>
            <FinanceProductBreakdown data={productsData} />
          </Grid>

          <Grid item md={6} xs={12}>
            {Object.keys(contractMarginDataElectricity).length ? (
              <ContractProfitabilityTreemap
                data={contractMarginDataElectricity?.treeMapSource}
                title={t('Quality of electricity customers')}
              />
            ) : null}
          </Grid>

          <Grid item md={6} xs={12}>
            {Object.keys(contractMarginDataGas).length ? (
              <ContractProfitabilityTreemap
                data={contractMarginDataGas?.treeMapSource}
                title={t('Quality of gas customers')}
              />
            ) : null}
          </Grid>
        </Grid>
      </Container>

      {isLoading ? <SplashScreen /> : null}
    </Box>
  );
}

HomePage.getLayout = (page) => (
  <AuthGuard>
    <PageLayout>{page}</PageLayout>
  </AuthGuard>
);

export default HomePage;
