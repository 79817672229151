import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Chart } from '../../__common__/Chart';

export default function ChartLine({ data, labels }) {
  const theme = useTheme();

  const chartOptions = {
    chart: {
      background: 'transparent',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: ['#2F3EB1'],
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    grid: {
      show: false,
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    theme: {
      mode: theme.palette.mode,
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: false,
    },
    xaxis: {
      categories: labels,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
  };

  const chartSeries = [{ data, name: '€' }];

  return <Chart options={chartOptions} series={chartSeries} type="area" />;
}

ChartLine.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number),
  labels: PropTypes.arrayOf(PropTypes.string),
};

ChartLine.defaultProps = {
  data: [],
  labels: [],
};
