import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import {
  Box, Grid, Typography, Card,
} from '@mui/material';
import ChartLine from './ChartLine';

export default function FinanceOverview({ data }) {
  const { t } = useTranslation();
  return (
    <Card>
      <Grid container>
        <Grid
          item
          md={4}
          xs={12}
          sx={{
            alignItems: 'center',
            borderRight: (theme) => ({
              md: `1px solid ${theme.palette.divider}`,
            }),
            borderBottom: (theme) => ({
              md: 'none',
              xs: `1px solid ${theme.palette.divider}`,
            }),
            display: 'flex',
            justifyContent: 'space-between',
            p: 3,
          }}
        >
          <div>
            <Typography color="textSecondary" variant="h3" mb={2}>
              {t('Revenue')}
              {' '}
              {data.currentMonthName}
            </Typography>
            <Typography variant="h5">
              €
              {numeral(data.revenue).format('0,0')}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              vs. €
              {numeral(data.prevYearRevenue).format('0,0')}
              &nbsp;
              {t('last')}
              {' '}
              {data.currentMonthName}
            </Typography>
          </div>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              height: 54,
              width: 177,
            }}
          >
            <ChartLine data={data.revenueValuesHistory} labels={data.revenueLabelsHistory} />
          </Box>
        </Grid>

        <Grid
          item
          md={4}
          xs={12}
          sx={{
            alignItems: 'center',
            borderRight: (theme) => ({
              md: `1px solid ${theme.palette.divider}`,
            }),
            borderBottom: (theme) => ({
              xs: `1px solid ${theme.palette.divider}`,
              md: 'none',
            }),
            display: 'flex',
            justifyContent: 'space-between',
            p: 3,
          }}
        >
          <div>
            <Typography color="textSecondary" variant="h3" mb={2}>
              {t('Direct Costs')}
              {' '}
              {data.currentMonthName}
            </Typography>
            <Typography variant="h5">
              €
              {numeral(data.costs).format('0,0')}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              vs. €
              {numeral(data.prevYearCosts).format('0,0')}
              &nbsp;
              {t('last')}
              {' '}
              {data.currentMonthName}
            </Typography>
          </div>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              height: 54,
              width: 177,
            }}
          >
            <ChartLine data={data.costsValuesHistory} labels={data.costsLabelsHistory} />
          </Box>
        </Grid>

        <Grid
          item
          md={4}
          xs={12}
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            p: 3,
          }}
        >
          <div>
            <Typography color="textSecondary" variant="h3" mb={2}>
              {t('Gross Margin')}
              {' '}
              {data.currentMonthName}
            </Typography>
            <Typography variant="h5">
              €
              {numeral(data.grossMargin).format('0,0')}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              vs. €
              {numeral(data.prevYearGrossMargin).format('0,0')}
              &nbsp;
              {t('last')}
              {' '}
              {data.currentMonthName}
            </Typography>
          </div>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              height: 54,
              width: 177,
            }}
          >
            <ChartLine data={data.grossMarginValuesHistory} labels={data.grossMarginLabelsHistory} />
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}

FinanceOverview.propTypes = {
  data: PropTypes.shape({
    prevYearCosts: PropTypes.number,
    currentMonthName: PropTypes.string,
    prevYearGrossMargin: PropTypes.number,
    grossMargin: PropTypes.number,
    costs: PropTypes.number,
    prevYearRevenue: PropTypes.number,
    revenue: PropTypes.number,
    grossMarginValuesHistory: PropTypes.arrayOf(PropTypes.number),
    costsValuesHistory: PropTypes.arrayOf(PropTypes.number),
    revenueValuesHistory: PropTypes.arrayOf(PropTypes.number),
  }),
};

FinanceOverview.defaultProps = {
  data: {},
};
