import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Chart } from '../../__common__/Chart';

export default function BarChart({ data }) {
  const theme = useTheme();

  const chartOptions = {
    chart: {
      background: 'transparent',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: ['#2F3EB1'],
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    grid: {
      show: false,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    stroke: {
      width: 0,
    },
    theme: {
      mode: theme.palette.mode,
    },
    tooltip: {
      enabled: false,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
  };

  const chartSeries = [{ data }];

  return <Chart options={chartOptions} series={chartSeries} type="bar" width={120} />;
}

BarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number),
};

BarChart.defaultProps = {
  data: [],
};
